import React from 'react';
import '../Styles/ArtificialIntelligence.css';
import Header from './Header';
import Footer from './Footer';
import data from '../markData.json';
import "@fontsource/roboto"; // Defaults to weight 400
import "@fontsource/roboto/400.css"; // Specify weight
import "@fontsource/roboto/400-italic.css"; // Specify weight and style



const ArtificialIntelligence = () => {

    const { BusinessIntelligence} = data; 
  return (
  
    
    
        <div className="artificial">
          <div className="div">
            <p className="text-wrapper">{data.artificial.div['text-wrapper']}</p>
            <div className="group">
              <div className="overlap-group">
                <img className="line" src={data.artificial.img.lineImage}/>
                <div className="frame"><img className="brandmark-design" src={data.artificial.img.brandmark} /></div>
              </div>
            </div>
            <Header/>
            <Footer/>
            <div className="overlap-3-ai">
              <p className="text-wrapper-23">{data.artificial.div.p}</p>
            </div>
            <div className="div-2">
              <div className="heading-AI-powered">{data.artificial.div['heading-AI-powered']}</div>
             <div className="main-box">
              <div className="div-collection-box">
                <div className="figure-collection"> 
                    <img  className="figure-collection" src={data.artificial.img.intelligent}/>
                </div>
                <div className="heading">Intelligent</div>
                <p className="adipiscing-elit-sed">{data.artificial.div['adipiscing-elit-sed']}</p>
                <div className="symbol"><img  src={data.artificial.img.aeromark}/></div>
              </div>
              <div className="div-collection-box-2">
                <div className="figure-collection-2"><img className="figure-collection-2" src={data.artificial.img.visualization}/></div>
                <div className="heading-2">{data.artificial.div['heading-2']}</div>
                <p className="adipiscing-elit-sed-2">{data.artificial.div['adipiscing-elit-sed-2']}</p>
                <div className="symbol"><img src={data.artificial.img.aeromark}/></div>
              </div>
              <div className="div-collection-box-3">
                <div className="figure-collection-3"><img  className="figure-collection-3" src={data.artificial.img.creativity}/></div>
                <div className="heading-creativity">{data.artificial.div['heading-creativity']}</div>
                <p className="adipiscing-elit-sed-2">{data.artificial.div['adipiscing-elit-sed']}</p>
                <div className="symbol"><img src={data.artificial.img.aeromark}/></div>
              </div>
              <div className="div-collection-box-4">
                <div className="figure-collection-4"> <img className="figure-collection-4" src={data.artificial.img.flexible} /></div>
                <div className="heading-flexible">{data.artificial.div['heading-flexible']}</div>
                <p className="adipiscing-elit-sed-2">{data.artificial.div['adipiscing-elit-sed']}</p>
                <div className="symbol"><img  src={data.artificial.img.aeromark}/></div>
              </div>
              </div>
            </div>
            <div className="div-3">
              <div className="heading-news">{data.artificial.div['heading-news']}</div>
              <div className="container-flex">
              <div className="div-blog-inner-box">
                <div className="figure-blog-png"><img className="figure-blog-png" src={data.artificial.img['image-11']}/></div>
                <p className="link-heading-the">{data.artificial.div['link-heading-the']}</p>
                <div className="symbol-2"><img src={data.artificial.img['calendar']}/></div>
                <div className="list-item-june">{data.artificial.div['list-item-june']}</div>
                <div className="symbol-3"><img src={data.artificial.img['comment']}/></div>
                <div className="list-item-comments">{data.artificial.div['list-item-comments']} </div>
              </div>
              <div className="div-blog-inner-box-2">
                <div className="figure-blog-img-png"><img className="figure-blog-img-png" src={data.artificial.img['image-12']}/></div>
                <p className="link-heading-AI">{data.artificial.div['link-heading-AI']}</p>
                <div className="symbol-2"><img src={data.artificial.img['calendar']}/></div>
                <div className="list-item-june">{data.artificial.div['list-item-june']}</div>
                <div className="symbol-3"><img src={data.artificial.img['comment']}/></div>
                <div className="list-item-comments">{data.artificial.div['list-item-comments']}</div>
              </div>
              <div className="div-blog-inner-box-3">
                <div className="figure-blog-png-2"><img className="figure-blog-png-2" src={data.artificial.img['image-13']}/></div>
                <p className="link-heading-when">{data.artificial.div['link-heading-when']}</p>
                <div className="symbol-2"><img src={data.artificial.img['calendar']}/></div>
                <div className="list-item-june">{data.artificial.div['list-item-june']}</div>
                <div className="symbol-3"><img src={data.artificial.img['comment']}/></div>
                <div className="list-item-comments">{data.artificial.div['list-item-comments']}</div>
              </div>
              </div>
            </div>
            <div className="group-15">
              <div className="frame-3">
                <div className="overlap-group-3">
                  <img className="icon-HRSD" src={data.artificial.img.iconhr} />
                  <div className="text-wrapper-24">{data.artificial.div['text-wrapper-24']}</div>
                </div>
              </div>
              <div className="frame-4">
                <div className="overlap-4">
                  <div className="text-wrapper-25">{data.artificial.div['text-wrapper-25']}</div>
                  <img className="icon-customer" src={data.artificial.img.iconcs} />
                </div>
              </div>
            </div>
          </div>
        </div>
     
    
  )
}

export default ArtificialIntelligence;