import React, { useState, useEffect } from "react";
import "../Styles/Career.css";
import data from "../markData.json";
import Footer from "./Footer";
import FetchData, { countJobsByTechnology } from "./FetchData";
import "@fontsource/roboto";
import { useQuery } from "@tanstack/react-query";
import Header from "./Header.js";
import { useNavigate } from "react-router-dom";
import Error from "../Error.js";

// Fetch careers from API
const fetchCareer = async () => {
  try {
    const response = await fetch("/getCareers");
    if (!response.ok) throw new Error("Network response was not ok");
    return response.json();
  } catch (error) {
    console.error("Error fetching careers:", error);
    throw error; // Ensure react-query handles the error
  }
};

const Career = () => {
  
  const { Career: localCareer } = data;

  const [jobCounts, setJobCounts] = useState({});
  const [jobs, setJobs] = useState([]);
  const [filterJobs, setFilterJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  

  const { isSuccess, data: fetchedData,isError, error: fetchError } = useQuery({
    queryKey: ["getCareer"],
    queryFn: fetchCareer,
    enabled: !loading, // Ensure fetching starts after loading spinner
  });

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (isError) {
      setError(fetchError);
      return;
    }

    if (isSuccess && fetchedData) {
      try {
        const jobData = fetchedData.map((record) => ({
          job_title: record.job_title?.S || "Job title not available",
          location: record.location?.S || "Location not provided",
          technology: record.Technology?.S || "Technology not specified",
          job_id: record.job_id?.S || "N/A",
          job_summary: record.job_summary?.S || "No summary available",
        }));
        setJobs(jobData);
        setFilterJobs(jobData);

        // Count jobs by category
        setJobCounts({
          ServiceNow: countJobsByTechnology(jobData, "ServiceNow"),
          Frontend: countJobsByTechnology(jobData, "Frontend"),
          ArtificialIntelligence: countJobsByTechnology(jobData, "ArtificialIntelligence"),
          WebDevelopment: countJobsByTechnology(jobData, "WebDevelopment"),
          CyberSecurity: countJobsByTechnology(jobData, "CyberSecurity"),
        });
      } catch (err) {
        console.error("Error processing job data:", err);
        setError("Error processing job data");
      }
    } else if (localCareer) {
      setJobs(localCareer);
      setFilterJobs(localCareer);
    }
  }, [localCareer, isSuccess, fetchedData, isError, fetchError]);


  if (error) {
    return <Error message={error.message || "An error occurred"} />;
  }
  return (
    <div>
      <Header />

      {loading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}

      {!loading && (
        <>
          <section className="section-career">
            <div className="container-larg-career">
              <div className="w-layout-grid-career feature-item-career">
                <img
                  src={data.careers.div.img["hire-2"]}
                  alt="hiring-img"
                  className="hire-img"
                />
                <div className="text-weight-medium-career">
                  Get to know us and join our team. We’d love to have more talented people on board. Check out our open positions.
                </div>
              </div>
            </div>
          </section>

          <div className="container-large-copy" id="fetch-data-section">
         
            <FetchData filterJobs={filterJobs} />
          </div>
        </>
      )}

      <Footer />
    </div>
  );
};

export default Career;