import React from 'react';
import data from '../markData.json'; // Adjust the path as necessary
import '../Styles/Webdevelopment.css';
import Footer from './Footer';
// import Header from './Header';
import LoginModal from './Login.js';
import "@fontsource/roboto"; // Defaults to weight 400
import "@fontsource/roboto/400.css"; // Specify weight
import "@fontsource/roboto/400-italic.css"; // Specify weight and style
import { useState } from 'react';

const WebDevelopment = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
      const [showLoginModal, setShowLoginModal] = useState(false);
          const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  return (
    <div className="web-development">
      <div className="div">
      <div data-animation="default" className="nav w-nav" data-easing2="ease" data-collapse="medium" role="banner">
        <div className="nav-container">
          {/* Logo */}
          <div className="logo-wrapper">
            <a href="/" className="logo w-nav-brand">
              <img className="logo-img" src={data.header.div.brandmark} alt="Logo" />
            </a>

            {/* Navigation Links */}
            <nav className={`nav-menu w-nav-menu ${isMenuOpen ? 'open' : ''}`}>
              <a href="/" className="nav-link w-nav-link">
                {data.header.div['text-wrapper-1-h ']}
              </a>
              <a href="/about" className="nav-link w-nav-link"  style={{ backgroundImage: "url('/images/home/cardview-back-img.png')", backgroundSize: 'contain' }}>{data.header.div['text-wrapper-2-h ']}</a>
              <a href="/careers" className="nav-link w-nav-link">{data.header.div['text-wrapper-3-h']}</a>
              <a href="/contact" className="nav-link w-nav-link">{data.header.div['text-wrapper-4-h ']}</a>
            </nav>
          </div>

          {/* Actions */}
          <div className="actions-wrapper">
          <button onClick={() => setShowLoginModal(true)} className="button small hide-mobile-portrait w-button">Login</button>

            {/* Mobile Menu Button */}
            <div className="nav-menu-button w-nav-button" onClick={toggleMenu}>
              <div className="menu-icon">
                <div className="menu-icon-line-top"></div>
                <div className="menu-icon-line-bottom"></div>
              </div>
            </div>
          </div>
        </div>

        {/* Dropdown Menu */}
        {isMenuOpen && (
          <div className="dropdown-menu">
            <a href="/" className="dropdown-link">Home</a>
            <a href="/careers" className="dropdown-link">Careers</a>
            <a href="/contact" className="dropdown-link">Contact</a>
            <a onClick={() => setShowLoginModal(true)} className="dropdown-link">Login</a>
          </div>
        )}
      </div>
      {showLoginModal && <LoginModal closeModal={() => setShowLoginModal(false)} />}
        <div className="overlap">
          <img className="line" alt="Line" src={data.webDevelopment.Images.line2} />
          <div className="frame">
            <img className="brandmark-design" alt="Brandmark design" src={data.webDevelopment.Images.brandmark} />
          </div>
        </div>
        <div className="main-wrapper">
        <div className="overlap-group-wrapper">
          <div className="overlap-group">
            <p className="text-wrapper">{data.webDevelopment.content1}</p>
          </div>
        </div>
        <div className="overlap-wrapper">
          <div className="div-wrapper-2">
            <p className="p">{data.webDevelopment.content2}</p>
          </div>
        </div>
        <div className="frame-2">
          <div className="containers-provide-wrapper">
            <p className="containers-provide">{data.webDevelopment.content3}</p>
          </div>
        </div>
        <div className="frame-3">
          <div className="overlap-2">
            <p className="text-wrapper-2">{data.webDevelopment.content4}</p>
          </div>
        </div>
        <div className="frame-4">
          <div className="overlap-3-18">
            <p className="text-wrapper-3">{data.webDevelopment.content5}</p>
          </div>
        </div>
        <div className="frame-5">
          <div className="implementation-is-wrapper">
            <p className="implementation-is">{data.webDevelopment.content6}</p>
          </div>
        </div>
        </div>
        <div className="frame-6">
          <div className="text-wrapper-6">{data.webDevelopment.content}</div>
          <p className="text-wrapper-4">{data.webDevelopment.content7}</p>
        </div>
        <div className="web-development-wrapper">
          <p className="text-wrapper-5">{data.webDevelopment.content8}
          </p>
        </div>
        <div className="overlap-4">
          <div className="group">
            <img className="image" alt="dell" src={data.webDevelopment.Images.develop} />
            <img className="img" alt="hplap" src={data.webDevelopment.Images.testing} />
            <img className="connector-line" alt="Connector line" src={data.webDevelopment.Images.line} />
            <img className="image-2" alt="lenovo" src={data.webDevelopment.Images.research} />
            <img className="image-3" alt="apple" src={data.webDevelopment.Images.content} />
            <img className="connector-line-2" alt="Connector line" src={data.webDevelopment.Images.line}  />
            <img className="image-4" alt="vivo" src={data.webDevelopment.Images.design} />
            <img className="connector-line-3" alt="Connector line" src={data.webDevelopment.Images.line} />
            <img className="image-5" alt="oppo" src={data.webDevelopment.Images.plan} />
            <img className="connector-line-4" alt="Connector line" src={data.webDevelopment.Images.line}  />
            <img className="connector-line-5" alt="Connector line" src={data.webDevelopment.Images.line} />
            <div className="frame-8"><p className='text'>{data.webDevelopment.textLabels.research}</p>
          </div>
          <div className="frame-9"><p className='text'>{data.webDevelopment.textLabels.plan}</p>
          </div>
          <div className="frame-10"><p className='text'>{data.webDevelopment.textLabels.design}</p>
          </div>
          <div className="frame-11"><p className='text'>{data.webDevelopment.textLabels.content}</p>
        </div>
        <div className="frame-12"><p className='text'>{data.webDevelopment.textLabels.develop}</p>
        </div>
        <div className="frame-13"><p className='text'>{data.webDevelopment.textLabels.testing}</p>
        </div>
          </div>
        </div>
        {/* <Footer/> */}
      </div>
    </div>
  );
};

export default WebDevelopment;