import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import '../Styles/Contact.css';
import data from '../markData.json';
import Footer from './Footer';
import Header from './Header.js';
import Error from '../Error.js';
import { Img } from "@react-email/components";
// import { ArrowRight } from 'react-bootstrap-icons';
import "@fontsource/roboto"; // Defaults to weight 400
import "@fontsource/roboto/400.css"; // Specify weight
import "@fontsource/roboto/400-italic.css"; // Specify weight and style

const Contact = () => {

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  }); 

  const setMessage = (message) => {
    if(formData.message === '') {
      setFormData((prevData) => ({
        ...prevData, message
      }));
    }
  };

  const Email = () => {
    return <Img src="/brandmark-design.png" alt="Cat" width="40px" height="30px" />;
  };
  const [responseMessage, setResponseMessage] = useState('');

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare email content as HTML
    const emailContent = `
     <body style="font-family: 'Poppins', Arial, sans-serif"

@media screen and (max-width: 600px) {
    .content {
        width: 100% !important;
        display: block !important;
        padding: 10px !important;
    }
    .header, .body, .footer {
        padding: 20px !important;
    }
  }>
    <table width="100%" border="0" cellspacing="0" cellpadding="0">
        <tr>
            <td align="center" style="padding: 20px;">
                <table class="content" width="600" border="0" cellspacing="0" cellpadding="0" style="border-collapse: collapse; border: 1px solid #cccccc;">
                    <!-- Header -->
                    <tr>
                        <td class="header" style="background-color: #f0ba19; padding: 40px; text-align: center; color: white; font-size: 24px;">
                        
                            <img
        src="https://marktechnologyimages.s3.us-east-1.amazonaws.com/brandmark-design+(6).png"
        alt="Brand Logo"
        style="display: block; width: 80px;"
      />

                        </td>
                    </tr>

                    <!-- Body -->
                    <tr>
                        <td class="body" style="padding: 10px; text-align: left; font-size: 16px; line-height: 1.6;">
                        Hello, <br>
                        <p style="font-size: 14px; line-height: 1.7; margin-top: 8px;">
                            Name: ${formData.name}
                          </p>
                        
                        <p style="font-size: 14px; line-height: 1.7; margin-top: 8px;">Email: ${formData.email}</p>
                        <p style="font-size: 14px; line-height: 1.7; margin-top: 8px;">
                          Description: ${formData.message}
                        </p>          
                        </td>
                    </tr>
                    <tr>
                        <td class="body" style="padding: 10px; text-align: left; font-size: 16px; line-height: 1.6;">
                            <p style="font-size: 14px; line-height: 1.7; margin-top: 10px;">
                                This is an annual reminder that your use of marktechnology is governed
                                by the marktechnology Customer Agreement, or other written agreement
                                between marktechnology India Private Limited and you governing such
                                use (“Agreement"). The Agreement incorporates our service terms,
                                privacy policy, and other relevant conditions governing your use of
                                marktechnology services, and explains our response to non-compliance or
                                misuse.
                              </p>       
                              
                              <p style="font-size: 14px; line-height: 1.6;">
                                Thank you for using our services.
                              </p>
                              <p style="font-size: 12px; line-height: 1.6; color: black; font-style: italic;">
                                Please do not reply to this email as responses are not monitored
                              </p>
                              <hr style="border: none; height: 1px; background-color: #c3c3c3; width: 80%;" />
                              <div
                              style="text-align: center; padding: 15px; font-size: 12px; color: black; cursor: pointer;"
                            >
                              marktechnology | career | home | contact | about
                            </div>
                        </td>
                    </tr>
                    <!-- Footer -->
                    <tr>
                        <td class="footer" style="background-color: #f0ba19; padding: 40px; text-align: center; color: white; font-size: 14px;">
                        Copyright &copy; 2025 | marktechnology
                        <br>
                        <span style="font-size: 11px;">
                            Chennai | 044 1234 |
                            <a
                              href="/"
                              style="color: #fff; text-decoration: underline;"
                            >
                              Unsubscribe
                            </a>
                          </span>
                        </td>
                        
                    </tr>
                </table>
            </td>
        </tr>
    </table>
</body>

    `;

    // Prepare JSON data for the email API
    const emailData = {
      subject: `New Message from MarkTechnology`,
      text: `You have received a new message from ${formData.name}.`,
      message: emailContent, // Include the formatted HTML content here
      
    };

    try {
      const response = await fetch('/send_email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(emailData),
      });

      if (response.ok) {
        setResponseMessage('Your message has been sent successfully!');
        setFormData({ name: '', email: '', message: '' });
      
        // Clear the message after 3 seconds
        setTimeout(() => {
          setResponseMessage('');
        }, 3000);
      } else {
        setResponseMessage('Failed to send your message. Please try again later.');
      }
    } catch (error) {
      console.error('Error:', error);
      setResponseMessage('An error occurred while sending your message.');
    }
  };

  try {
    return (
      <div className="contact-us">
       <Header/>
  
         
  
        {/* Contact Form Section */}
        <section className="section-contact">
          <div className="container-large-contact">
            <div className="grid-2-column-contact">
              <div className="header-content-contact">
                <h1 className="heading-contact extra-large-contact">
                  <span className="text-gradient-overlay">{data.contactuspage.div.frame['text-wrapper-11']}</span>
                </h1>
                <div className="spacer-32-contact"></div>
                <p className="paragraph-contact large-contact">
                  {data.contactuspage.div.frame['p-1']}
                  <a href="mailto:hr@marktechnology.in">{data.contactuspage.div.frame['text-wrapper-15']}</a>
                </p>
              </div>
              <div className="form-block-contact w-form-contact">
                <form id="wf-form-Contact-Form" name="wf-form-Contact-Form" method="get" className="login-form" onSubmit={handleSubmit}>
                  <input
                    className="text-input-co w-input"
                    maxLength="256"
                    name="name"
                    placeholder="Name"
                    type="text"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                  <input
                    className="text-input-co w-input"
                    maxLength="256"
                    name="email"
                    placeholder="Email address"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                  <textarea
                    id="Message"
                    name="message"
                    maxLength="5000"
                    placeholder="Your message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                    className="text-input-co area w-input"
                  ></textarea>
                  <div className="spacer-4"></div>
                  <input type="submit" className="button-contact full-width w-button-contact" value="Send message" />
                </form>
                {responseMessage && <div className="response-message">{responseMessage}</div>}
              </div>
            </div>
          </div>
          </section>
          {/* flip card view section */}
          <section className="section-contact">
          <div className="container-large-contact">
          <h2 className="heading-contact large"><span className="text-gradient-overlay">{data.contactuspage.div.frame['text-wrapper-19']}</span></h2>
          <div className="w-layout-grid-contact card-list-contact">
          <div className="flip-card">
                      <div className="flip-card-inner">
                          <div className="flip-card-front">
                              <img src={data.aboutUsProductAnd.img['servicenow-img']} alt="ServiceNow" className="card-image" />
                          </div>
                          <div className="flip-card-back">
                              <h3 className='back-heading'>Service Now</h3>
                              <a href="#" onClick={() => setMessage(`I am reaching out to inquire about services and support related to the ServiceNow platform. We are interested in exploring solutions that could enhance our current processes and improve efficiency. If you offer consultancy, development, or integration services for ServiceNow, I would appreciate the opportunity to discuss how we can collaborate.Please let me know your availability for a call or meeting, and we can outline the specific requirements and areas of interest.Thank you in advance`)} className="btn-more">Talk to us!</a>
                              <p>If you are working on a specific ServiceNow implementation or project and need technical or consulting assistance, reaching out to your ServiceNow account manager or partner.</p>
                          </div>
                      </div>
                  </div>
                  <div className="flip-card">
                      <div className="flip-card-inner">
                          <div className="flip-card-front">
                              <img src={data.aboutUsProductAnd.img['BI-img']} alt="Businessintelligence" className="card-image" />
                          </div>
                          <div className="flip-card-back">
                          <h3 className='back-heading'>Business intelligence</h3>
                          <a href="#" onClick={() => setMessage("I am reaching out to inquire about services and support related to the Business intelligence platform. We are interested in exploring solutions that could enhance our current processes and improve efficiency. If you offer consultancy, development, or integration services for Business intelligence, I would appreciate the opportunity to discuss how we can collaborate.Please let me know your availability for a call or meeting, and we can outline the specific requirements and areas of interest.Thank you in advance")} className="btn-more">Talk to us!</a>
                              <p>If you are working on a specific Business intelligence implementation or project and need technical or consulting assistance, reaching out to your Business intelligence account manager or partner.</p>
                          </div>
                      </div>
                  </div>
                  <div className="flip-card">
                      <div className="flip-card-inner">
                          <div className="flip-card-front">
                              <img src={data.aboutUsProductAnd.img['web-img']} alt="webdevelopment" className="card-image" />
                          </div>
                          <div className="flip-card-back">
                          <h3 className='back-heading'>Web Development</h3>
                          <a href="#" onClick={() => setMessage("I am reaching out to inquire about services and support related to the Web Development platform. We are interested in exploring solutions that could enhance our current processes and improve efficiency. If you offer consultancy, development, or integration services for Web Development, I would appreciate the opportunity to discuss how we can collaborate.Please let me know your availability for a call or meeting, and we can outline the specific requirements and areas of interest.Thank you in advance")} className="btn-more">Talk to us!</a>
                              <p>If you are working on a specific Web Development implementation or project and need technical or consulting assistance, reaching out to your Web Development account manager or partner.</p>
                          </div>
                      </div>
                  </div>
                  <div className="flip-card">
                      <div className="flip-card-inner">
                          <div className="flip-card-front">
                              <img src={data.aboutUsProductAnd.img['Ai-img']} alt="Artificialintelligence" className="card-image" />
                          </div>
                          <div className="flip-card-back">
                          <h3 className='back-heading'>Artificial Intelligence</h3>
                              <p>If you are working on a specific Artificial Intelligence implementation or project and need technical or consulting assistance, reaching out to your Artificial Intelligence account manager or partner.</p>
                              <a href="#" onClick={() => setMessage("I am reaching out to inquire about services and support related to the Artificial Intelligence platform. We are interested in exploring solutions that could enhance our current processes and improve efficiency. If you offer consultancy, development, or integration services for Artificial Intelligence, I would appreciate the opportunity to discuss how we can collaborate.Please let me know your availability for a call or meeting, and we can outline the specific requirements and areas of interest.Thank you in advance")} className="btn-more">Talk to us!</a>
                          </div>
                      </div>
                  </div>
                  <div className="flip-card">
                      <div className="flip-card-inner">
                          <div className="flip-card-front">
                              <img src={data.aboutUsProductAnd.img['cyber-img']} alt="Artificialintelligence" className="card-image" />
                          </div>
                          <div className="flip-card-back">
                          <h3 className='back-heading'>Cyber Security</h3>
                              <p>If you are working on a specific Cybers Security implementation or project and need technical or consulting assistance, reaching out to your Cybers Security account manager or partner.</p>
                              <a href="#" onClick={() => setMessage("I am reaching out to inquire about services and support related to the Cyber Security platform. We are interested in exploring solutions that could enhance our current processes and improve efficiency. If you offer consultancy, development, or integration services for Cyber Security, I would appreciate the opportunity to discuss how we can collaborate.Please let me know your availability for a call or meeting, and we can outline the specific requirements and areas of interest.Thank you in advance")} className="btn-more">Talk to us!</a>
                          </div>
                      </div>
                  </div>
                  <div className="flip-card">
                      <div className="flip-card-inner">
                          <div className="flip-card-front">
                              <img src={data.aboutUsProductAnd.img['3d-img']} alt="Artificialintelligence" className="card-image" />
                          </div>
                          <div className="flip-card-back">
                          <h3 className='back-heading'>3D Design</h3>
                              <p>If you are working on a specific 3D-Designing implementation or project and need technical or consulting assistance, reaching out to your 3D-Designing account manager or partner.</p>
                              <a href="#"  onClick={() => setMessage("I am reaching out to inquire about services and support related to the 3D Design platform. We are interested in exploring solutions that could enhance our current processes and improve efficiency. If you offer consultancy, development, or integration services for 3D Design, I would appreciate the opportunity to discuss how we can collaborate.Please let me know your availability for a call or meeting, and we can outline the specific requirements and areas of interest.Thank you in advance")} className="btn-more">Talk to us!</a>
                          </div>
                      </div>       
                  </div>
                  </div>
        </div>
        </section>
        
        {/* Footer section */}
        <Footer />
      </div>
    );
  } catch (error) {
    if(error){
      console.log("this is error:",error);
      return <Error />
    }
  }

  
};

export default Contact;
