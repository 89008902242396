import './App.css';
import Home from './components/Home.js'
import { Routes,Route} from 'react-router-dom';
import AboutUsServiceNow from './components/servicenow.js';
import Footer from './components/Footer.js';
import ScrollToTop from './ScrollToTop.js';
import WebDevelopment from './components/Webdevelopment.js';
import BusinessIntelligence from '../src/components/BusinessIntelligence.js';
import ArtificialIntelligence from './components/ArtificialIntelligence.js';
import Career from '../src/components/Career.js';
import Contact from '../src/components/Contact.js';
import Register from './components/Register.js';
import About from '../src/components/About.js';
import Error from './Error.js';
import JobDescription from './components/JobDescription.js';
import ApplyJob from './components/ApplyJob.js';
import FetchData from './components/FetchData.js';
import TermsAndConditions from './components/TermsAndConditions.js';
import Login from './components/Login.js';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Maintenance from './components/Maintenance.js';




const queryClient = new QueryClient();


function App() {
  const isUnderMaintenance = false;

  if (isUnderMaintenance) {
    return <Maintenance />;
  }

  return (
    <QueryClientProvider client={queryClient}>
    <div className="App">
      <ScrollToTop/>
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/careers' element={<Career/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/register' element={<Register/>}/>
        <Route path='/about' element={<About />} />
        <Route path='/servicenow' element={<AboutUsServiceNow />} />
        <Route path='/footer' element={<Footer/>}/>
        <Route path='/webdevelopment' element={< WebDevelopment/>}/>
        <Route path='/businessintelligence' element={< BusinessIntelligence/>} />
        <Route path='/artificialIntelligence' element={< ArtificialIntelligence/>} />
        <Route path="/jobdes/:id" element={<JobDescription/>}/>
        <Route path='/applyjob/:id' element={<ApplyJob/>}/>
        <Route path='/fetchdata' element={<FetchData/>}/>
        <Route path='/termsandcondition' element={<TermsAndConditions/>}/>
        <Route path='*' element={<Error/>} />
        
        <Route path='/login' element={<Login/>}/>
        
     
        
        

       
      </Routes>
    </div>
    </QueryClientProvider>
  );
}

export default App;