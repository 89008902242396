import React, { useState } from 'react';
import data from '../markData.json';
import '../Styles/Home.css'; 
import Footer from './Footer.js';
import "@fontsource/roboto"; // Defaults to weight 400
import "@fontsource/roboto/400.css"; // Specify weight
import "@fontsource/roboto/400-italic.css"; // Specify weight and style
import Header from './Header.js';
import Error from '../Error.js';



const Home = () => {

    try {
        const [isMenuOpen, setIsMenuOpen] = useState(false);

  
    
     
  return (
  
<div className="whole-container">
    
    <Header/>
    {/* <!-- Hero Section --> */}
    <section className="section-home-header">
        <div className="container-large-home">
            <div className="header-layout-home">
                <div className="spacer-32"></div>
                <div className="spacer-64"></div> 
                    <h1 className="heading extra-large-home">
                        <span className="text-gradient-overlay-home">{data.home.div['text-gradient-overlay']}</span>
                        <div className="spacer-32"></div>
                        <p className='tech-p-home'>{data.home.div['tech-p']}</p>
                    </h1>
                    <p className="paragraph large-home-header">{data.home.div['paragraph large']}</p>
            </div>
        </div>
    </section>

    {/* <!-- Our service section --> */}
    <section className="section-home">
        <div id="learn-more" className="container-large-home">
            <h2 className="heading large-home"><span className="service-heading">{data.home.div.ourservice['heading large']}</span></h2>
            <div className="spacer-80"></div>
            </div>
            {/* <!-- Feature Cards --> */}
            <div className="card-list-home">
                {/* card item one */}
                <div className="card-item-home">
                <div className="head-image">
                <img src="https://cdn.prod.website-files.com/676988a4f280652b742d9a86/676988a4f280652b742d9b60_layout-dashboard.svg" alt="Dashboard icon" className="icon-large-home" />
                    <h3 className="heading medium-home">{data.home.div.ourservice['heading medium-1']}</h3></div>
                    <p className="paragraph-home">{data.home.div.ourservice['s-paragraph-1']}</p>
                </div>
                {/* card item two */}
                <div className="card-item-home">      
                <div className="head-image">        
                <img src="https://cdn.prod.website-files.com/676988a4f280652b742d9a86/676988a4f280652b742d9b56_radar.svg" loading="lazy" alt="Radar icon" className="icon-large-home" />
                    <h3 className="heading medium-home">{data.home.div.ourservice['heading medium-2']}</h3> </div>   
                    <p className="paragraph-home">{data.home.div.ourservice['s-paragraph-2']}</p>
                </div>
                {/* card item three */}
                <div className="card-item-home">
                <div className="head-image">
                <img src="https://cdn.prod.website-files.com/676988a4f280652b742d9a86/676988a4f280652b742d9b5f_wand-sparkles.svg" loading="lazy" alt="Magic icon" className="icon-large-home"/>
                    <h3 className="heading medium-home">{data.home.div.ourservice['heading medium-3']}</h3></div>
                    <p className="paragraph-home">{data.home.div.ourservice['s-paragraph-3']}</p>
                </div>
                {/* card item four */}
                <div className="card-item-home">
                <div className="head-image">
                    <img src="https://cdn.prod.website-files.com/676988a4f280652b742d9a86/676988a4f280652b742d9b58_mouse-pointer-click.svg" loading="lazy" alt="Mouse icon" className="icon-large-home"/>
                    <h3 className="heading medium-home">{data.home.div.ourservice['heading medium-4']}</h3></div>
                    <p className="paragraph-home">{data.home.div.ourservice['s-paragraph-4']}</p>
                </div>
                {/* card item five */}
                <div className="card-item-home">
                <div className="head-image">
                <img src="https://cdn.prod.website-files.com/676988a4f280652b742d9a86/676988a4f280652b742d9b58_mouse-pointer-click.svg" loading="lazy" alt="Mouse icon" className="icon-large-home"/>
                    <h3 className="heading medium-home">{data.home.div.ourservice['heading medium-5']}</h3></div>
                    <p className="paragraph-home">{data.home.div.ourservice['s-paragraph-5']}</p>
                </div>
            </div>
        </section>
    
        {/* <!-- Section 2: Features and Benefits --> */}
         {/* <!-- Who we are section --> */}
        <section className="section-home">
            <div className="container-large-home">
                            <div className="heading large-home-two">
                                <h2 className="weare-heading">{data.home.div.ourservice['head-who']}</h2>
                                <p className="text-color-accent text-weight-medium-home">{data.home.div.ourservice['text-who sub']}</p>
                            </div>
                            <img src={data.home.div.img['hero-img-2']}
                                loading="lazy" alt="Product image" className="feature-image-home"/>
            </div>
        </section>
    
        {/* <!-- Section 3: Customer Testimonials --> */}
         {/* <!-- industry section --> */}
        <section className="section-home">
            <div className="container-large-home">
                <h2 className="heading large-home"><span className="indus-heading">{data.home.div.Industry.heading}</span></h2>
                <div className="spacer-64"></div>
                <div className="testimonials-list-home">
                    <div className="testimonials-card-home">
                        <div className="testimonials-content-top">
                            <div className="head-image">
                            <div className="icon-home">{data.home.div.Industry['icon-1']}</div>
                            <div className="indus-header-home">{data.home.div.Industry['indus-h-1']}</div></div>
                            <div className="paragraph large-home">{data.home.div.Industry['paragraph-1']}</div>
                        </div>
                    </div>

                    <div className="testimonials-card-home">
                        <div className="testimonials-content-top">
                            <div className="head-image">
                            <div className="icon-home">{data.home.div.Industry['icon-2']}</div>
                            <div className="indus-header-home">{data.home.div.Industry['indus-h-2']}</div></div>
                            <div className="paragraph large-home">{data.home.div.Industry['paragraph-2']}</div>
                        </div>
                    </div>

                    <div className="testimonials-card-home">
                        <div className="testimonials-content-top">
                            <div className="head-image">
                            <div className="icon-home">{data.home.div.Industry['icon-3']}</div>
                            <div className="indus-header-home">{data.home.div.Industry['indus-h-3']}</div></div>
                            <div className="paragraph large-home">{data.home.div.Industry['paragraph-3']}</div>
                        </div>
                    </div>

                    <div className="testimonials-card-home">
                        <div className="testimonials-content-top">
                        <div className="head-image">
                             <div className="icon-home">{data.home.div.Industry['icon-4']}</div>
                            <div className="indus-header-home">{data.home.div.Industry['indus-h-4']}</div></div>
                            <div className="paragraph large-home">{data.home.div.Industry['paragraph-4']}</div>
                        </div>
                    </div>

                    <div className="testimonials-card-home">
                        <div className="testimonials-content-top">
                            <div className="head-image">
                            <div className="icon-home">{data.home.div.Industry['icon-5']}</div>
                            <div className="indus-header-home">{data.home.div.Industry['indus-h-5']}</div></div>
                            <div className="paragraph large-home">{data.home.div.Industry['paragraph-5']}</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    

        {/* <!-- Section 4: FAQs --> */}
        <section className="section-home">
            <div className="container-small-home">
                <div className="faq-wrapper-home">
                    <div className="faq-content-home">
                        <h2><span className="qus-heading">{data.home.div.Faq.heading}</span></h2>
                        <div className="spacer-24"></div>
                    </div>
                    <div className="faq-list-home">
                        {/* <!-- FAQ Item 1 --> */}
                        <div className="faq-accordion-home">
                            <div className="faq-question-home">
                                <h3 className="heading regular-home">{data.home.div.Faq['qus-1']}</h3>
                            </div>
                            <div className="faq-answer-home">
                                <p className="paragraph-home-two">{data.home.div.Faq['ans-1']}</p>
                            </div>
                            <div className="spacer-32"></div>
                        </div>
                        <div className="faq-accordion-home">
                            <div className="faq-question-home">
                                <h3 className="heading regular-home">{data.home.div.Faq['qus-2']}</h3>
                            </div>
                            <div className="faq-answer-home">
                                <p className="paragraph-home-two">{data.home.div.Faq['ans-2']}</p>
                            </div>
                            <div className="spacer-32"></div>
                        </div>

                        <div className="faq-accordion-home">
                            <div className="faq-question-home">
                                <h3 className="heading regular-home">{data.home.div.Faq['qus-3']}</h3>
                            </div>
                            <div className="faq-answer-home">
                                <p className="paragraph-home-two">{data.home.div.Faq['ans-3']}</p>
                            </div>
                            <div className="spacer-32"></div>
                        </div>

                        <div className="faq-accordion-home">
                            <div className="faq-question-home">
                                <h3 className="heading regular-home">{data.home.div.Faq['qus-4']}</h3>
                            </div>
                            <div className="faq-answer-home">
                                <p className="paragraph-home-two">{data.home.div.Faq['ans-4']}</p>
                            </div>
                            <div className="spacer-32"></div>
                        </div>

                        <div className="faq-accordion-home">
                            <div className="faq-question-home">
                                <h3 className="heading regular-home">{data.home.div.Faq['qus-5']}</h3>
                            </div>
                            <div className="faq-answer-home">
                                <p className="paragraph-home-two">{data.home.div.Faq['ans-5']}</p>
                            </div>
                            <div className="spacer-32"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    
       
         {/* <!-- build the future section --> */}
        <div className="section-home cc-cta">
            <div className="container">
              <div className="cta-wrap-home">
                  <div className="cta-text">
                    <div className="heading-jumbo-small">{data.home.div.buildfuture.heading}</div>
                    <div className="paragraph-bigger cc-bigger-light">{data.home.div.buildfuture.paragraph}</div>
                  </div>
                  <div className='div-container'>
                  <a href="/contact" className="primary-button-home"><div>{data.home.div.buildfuture['btn-1']}</div></a>
                  <a href="/contact" className="primary-button-home"><div>{data.home.div.buildfuture['btn-2']}</div></a>
                  <a href="/contact" className="primary-button-home"><div>{data.home.div.buildfuture['btn-3']}</div></a>
                  </div>
              </div>
            </div>
          </div>
    
        {/* <!-- Footer section--> */}
        <Footer />
        </div>
        
          )
    } catch (error) {
        if(error){
            console.log("this is error:",error);
            return <Error />
        }
    }

    
}

export default Home;