import React, { useState, useEffect } from 'react';
import './Error.css';
import { Link } from 'react-router-dom';

const Error = () => {
  const [currentTheme, setCurrentTheme] = useState("light");

  
  const toggleTheme = () => {
    const root = document.documentElement;

    if (currentTheme === "dark") {
      root.style.setProperty("--bg-color", "#f0ba19");
      root.style.setProperty("--text-color", "black");
      setCurrentTheme("light");
    } else {
      root.style.setProperty("--bg-color", "#050505");
      root.style.setProperty("--text-color", "#fff");
      setCurrentTheme("dark");
    }
  };

  
  useEffect(() => {
    const colorSwitcher = document.querySelector("[data-theme-color-switch]");
    colorSwitcher.addEventListener("click", toggleTheme);

    
    return () => {
      colorSwitcher.removeEventListener("click", toggleTheme);
    };
  }, [currentTheme]);

  return (
    <main className="error-page">
      <div className="container-error">
        <div className="eyes">
          <div className="eye">
            <div className="eye__pupil eye__pupil--left"></div>
          </div>
          <div className="eye">
            <div className="eye__pupil eye__pupil--right"></div>
          </div>
        </div>

        <div className="error-page__heading">
          <h1 className="error-page__heading-title">Looks like you're lost</h1>
          <p className="error-page__heading-desciption">404 error</p>
        </div>

        <Link to='/' className='backLink'>  <a className="error-page__button" href="#" aria-label="back to home" title="back to home" > Back to Home</a></Link>
      </div>

      <button className="color-switcher" data-theme-color-switch>
      
        {currentTheme === "dark" ? "\u{1F319}" : "\u{2600}"}
     
      </button>
    </main>
  );
};

export default Error;
