import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Heart from './heart.png';
import Error from '../Error.js';

// Helper function to count jobs by technology
export function countJobsByTechnology(jobs, technology) {
  return jobs.filter(job => job.technology.toLowerCase() === technology.toLowerCase()).length;
}

function FetchData({ filterJobs = [] }) {
  const [search, setSearch] = useState('');
  const [selectedTechnology, setSelectedTechnology] = useState('');
  const [savedJobs, setSavedJobs] = useState(new Set());
  const [isSavedJobsView, setIsSavedJobsView] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const jobsPerPage = 10;

  // Memoized job filtering based on search and technology
  const filteredJobs = useMemo(() => {
    return filterJobs.filter((job) =>
      (search === '' || job.job_title.toLowerCase().includes(search.toLowerCase())) &&
      (selectedTechnology === '' || job.technology.toLowerCase() === selectedTechnology.toLowerCase())
    );
  }, [filterJobs, search, selectedTechnology]);

  // Jobs to display with pagination and saved job view
  const jobsToDisplay = useMemo(() => {
    if (isSavedJobsView) {
      return filterJobs.filter((job) => savedJobs.has(job.job_id));
    }
    const start = (currentPage - 1) * jobsPerPage;
    return filteredJobs.slice(start, start + jobsPerPage);
  }, [isSavedJobsView, filterJobs, savedJobs, filteredJobs, currentPage]);

  const totalPages = Math.ceil(filteredJobs.length / jobsPerPage);

  const handlePageChange = useCallback((newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  }, [totalPages]);

  // Toggle save/unsave job
  const toggleSaveJob = useCallback((job) => {
    setSavedJobs((prev) => {
      const updated = new Set(prev);
      if (updated.has(job.job_id)) {
        updated.delete(job.job_id);
      } else {
        updated.add(job.job_id);
      }
      return updated;
    });
  }, []);

  // Switch between saved jobs and all jobs view
  const toggleSavedJobsView = useCallback(() => {
    setIsSavedJobsView((prev) => !prev);
    setCurrentPage(1);
  }, []);

  // Remove job from saved jobs
  const handleRemoveSavedJob = useCallback((jobId) => {
    setSavedJobs((prev) => {
      const updated = new Set(prev);
      updated.delete(jobId);
      return updated;
    });
  }, []);

  // Extract unique technologies
  const uniqueTechnologies = useMemo(() => {
    return ['All Technologies', ...new Set(filterJobs.map((job) => job.technology))];
  }, [filterJobs]);

  // Handle category click (scroll to job filter and apply filter)
  const handleCategoryClick = (technology) => {
    setSelectedTechnology(technology);
    setCurrentPage(1);

    // Scroll to job section
    const jobSection = document.getElementById('job-listing-section');
    if (jobSection) {
      jobSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="main-container">
      {/* Open Positions Section */}
      <div className="feature-content-career">
          <h2 className="heading-career large">
            <span className="text-gradient-overlay">Open Positions</span>
          </h2>
        </div>

      
<section className="section-one">
  <div className="w-layout-grid-c faq-wrapper-career">
    {uniqueTechnologies
      .filter((tech) => tech !== 'All Technologies')
      .map((tech, index) => (
        <div 
          className="faq-accordion-s" 
          key={index} 
          onClick={() => handleCategoryClick(tech)}
          style={{ cursor: 'pointer' }}
        >
          <div className="faq-question-career">
            <h3 className="heading-career regular">{tech}</h3>
          </div>
          <div className="faq-answer-career">
            <p className="paragraph-count">{countJobsByTechnology(filterJobs, tech)}</p>
          </div>
        </div>
      ))}
  </div>
</section>



      <div className="container-large-copy" id="fetch-data-section">
          <h2 className="text-gradient-overlay">How We Hire Our Team</h2>
          <p>We like to keep things simple so we can focus on what really matters getting to know you.</p>                  
      </div>


      {/* Job Listings Section */}
      <div id="job-listing-section" className="job-app-container">
        <div className="explore-content">
          <div className="header-title">Explore Jobs</div>
          <button className="saved-btn" onClick={toggleSavedJobsView}>
            {isSavedJobsView ? 'Back to Job Listings' : (
              <>
                Saved Jobs
                <img className="heart-icon" src={Heart} alt="Heart Icon" />
                <span className="tool-tip">{savedJobs.size}</span>
              </>
            )}
          </button>
        </div>

        <div className="main-content">
          <aside className="left-sidebar">
            <h3 className="h3-filter">Filter Jobs</h3>
            <input
              type="text"
              placeholder="Search jobs..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="search-input"
            />
            <select
              value={selectedTechnology}
              onChange={(e) => setSelectedTechnology(e.target.value)}
              className="filter-select"
            >
              {uniqueTechnologies.map((tech, index) => (
                <option key={index} value={tech === 'All Technologies' ? '' : tech}>
                  {tech}
                </option>
              ))}
            </select>
          </aside>

          <div className="right-content">
            <ul className="job-list">
              {jobsToDisplay.length > 0 ? (
                jobsToDisplay.map((job) => (
                  <li key={job.job_id} className="job-item">
                    <NavLink className="job-div" to={`/jobdes/${job.job_id}`}>
                      <div className="title">{job.job_title}</div>
                      <div>
                        <strong>Technology:</strong> {job.technology}
                      </div>
                    </NavLink>
                    {isSavedJobsView ? (
                      <button
                        className="remove-button"
                        onClick={() => handleRemoveSavedJob(job.job_id)}
                      >
                        Remove
                      </button>
                    ) : (
                      <button
                        className={`heart-button ${savedJobs.has(job.job_id) ? 'saved' : ''}`}
                        onClick={() => toggleSaveJob(job)}
                      >
                        ❤
                      </button>
                    )}
                  </li>
                ))
              ) : (
                <Error />
              )}
            </ul>

            <div className="pagination-controls">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="pagination-button"
              >
                Previous
              </button>
              <span className="page">{`Page ${currentPage} of ${totalPages}`}</span>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="pagination-button"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FetchData;
