import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../Styles/JobDescription.css";
import Footer from "./Footer";
import { Link, useParams } from "react-router-dom";
import data from "../markData.json";
import brandimg from '././brandmark-design.png'
import LoginModal from './Login.js'; // Assuming LoginModal is a separate component
import Error from "../Error.js";

const JobDescription = ({ Career }) => {
  const { id } = useParams();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const [jobs, setJobs] = useState([]);
  const [jobDetail, setJobDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
   // header dropdown
           const [menuOpen, setMenuOpen] = useState(false);


           useEffect(() => {
            if (!Career) {
              fetch("/getCareers")
                .then((response) => {
                  if (!response.ok) throw new Error("Network response was not ok");
                  return response.json();
                })
                .then((data) => {
                  const transformedData = data.map((record) => ({
                    job_title: record.job_title?.S || "Job title not available",
                    location: record.location?.S || "Location not provided",
                    technology: record.Technology?.S || "Technology not specified",
                    job_id: record.job_id?.S || "N/A",
                    job_summary: record.job_summary?.S || "No summary available",
                    payscale: record.payscale?.S || "No Mention",
                    experience: record.Experience?.S || "Experience not provided",
                    opportunity: record.job_opportunity?.S || "N/A",
                    respons: record.key_respons?.S || "N/A",
                  }));
                  setJobs(transformedData);
                  setTimeout(() => setLoading(false), 1000);
                })
                .catch((error) => {
                  console.error("Error fetching jobdescription:", error);
                  setError(error.message);
                  setLoading(false);
                });
            } else {
              setJobs(Career);
              setTimeout(() => setLoading(false), 1000);
            }
          }, [Career]);

  useEffect(() => {
    if (jobs.length > 0) {
      const job = jobs.find((job) => job.job_id === id);
      setJobDetail(job);
    }
  }, [jobs, id]);
  if (error) {
    return <Error message={error.message || "An error occurred"} />;
  }
  return (
    <div>
    
        {/* Navigation Bar  */}
       <div className="navbar">
            <div className="nav-container">
                <div className="logo">
                    <a href="/"><img src={brandimg} alt="Logo" height="80px" /></a>
                </div>
                <ul className={`nav-links ${menuOpen ? 'show' : ''}`} onClick={() => setMenuOpen(false)}> 
                    <li><a href="/" >Home</a></li>
                    <li><a href="/about" >About</a></li>
                    <li><a href="/careers"  style={{ backgroundImage: "url('/images/home/cardview-back-img.png')", backgroundSize: 'contain' }}>Careers</a></li>
                    <li><a href="/contact">Contact</a></li>
                </ul>
            </div>
            {/* <button className="header-login" onClick={() => setShowLoginModal(true)}>Login</button> */}
            <button className="hamburger" onClick={() => setMenuOpen(!menuOpen)}>{menuOpen ? "✖" : "☰"}</button>
        </div>
        


    <div class="sub-heading">
    <nav class="breadcrumb">
    <a href="/careers">Career</a>
        <span>›</span>
        <a href="#" style={{ color: 'blue' }}>JobDescription</a>
    </nav>

    <nav class="breadcrumb-mobile">
            <a href="/careers">
               
                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <polyline points="15 18 9 12 15 6"></polyline>
                </svg>
                Back to Career
            </a>
        </nav>
  </div>


      {/* Login Modal (popup) */}
      {showLoginModal && <LoginModal closeModal={() => setShowLoginModal(false)} />}
{/* Loading Spinner */}
{loading && (
  <div className="loader-container">
      <div className="loader"></div>
      </div>
    )}

    {/* Show error if fetch fails */}
    {error && <p>Error: {error}</p>}

    {/* Show job details when data is loaded */}
    {!loading && !error && (
      <>


        {/* Job Details Section */}
        <div className="job-container">
      {jobDetail ? (
        <>
          {/* Job Header */}
          <div className="job-header">
            <div className="job-details">
              <h1>{jobDetail.job_title}</h1>
              <div className="highlight"></div>
              <p>
                <strong>Location:</strong> {jobDetail.location}
              </p>
              <p>
                <strong>Payscale:</strong> {jobDetail.payscale}
              </p>
              <p>
                <strong>Experience:</strong> {jobDetail.experience}
              </p>
              <p>
            <strong>Date:</strong> {new Date().toLocaleDateString('en-US', {
              day: '2-digit',
              month: 'short',
              year: 'numeric',
            })}
          </p>
            </div>
            <div className="job-image"> 
              {data.applyJob?.images?.["job-des-img"] && (
                <img src={data.applyJob.images["job-des-img"]} alt="Team Collaboration" />
              )}
            </div>
          </div>

          {/* Job Description */}
          <div className="job-description">
            <h2>Job description</h2>
            <div className="highlight"></div>
            <p className="job-clr">
              <strong>Requisition ID:</strong> {jobDetail.job_id}
            </p>
            <p className="job-clr">
                <strong>Technology:</strong> {jobDetail.technology}
              </p>
            <p className="job-clr">
              <strong>Position:</strong> {jobDetail.job_title}
            </p>
            <p className="job-clr">
              <strong>Location:</strong> {jobDetail.location}
            </p>
            
            <p className="job-clr">
              <strong>Summary:</strong> {jobDetail.job_summary}
            </p>
            

            <h3 className="job-clr">The opportunity</h3>
            <p className="job-clr">
            {jobDetail.opportunity}
            </p>
            <h3 className="job-clr">Your key responsibilities</h3>
            <ul>
              <div className="job-clr">
                {jobDetail.respons}
              </div>
              
            </ul>
          </div>
        </>
      ) : (
        <p>No job found with the provided ID.</p>
      )}
    
        

        {/* <!-- Additional Section: Whistleblower Act and Apply Now --> */}
        <div className="job-footer">
            <p className="job-clr"><strong>If you can demonstrate that you meet the criteria above, please contact us as soon as possible.</strong></p>
            
            <p className="job-clr">In compliance with the requirements of the Whistleblower Protection Act, our company has established the Procedure for reporting breaches of law and undertaking appropriate follow-up actions.</p>
            
            <div className="button-group">
                
                {jobDetail && (
                  <Link 
                    to={`/applyjob/${jobDetail.job_id}`} 
                    state={{ jobDetail }} 
                className="apply-now">Apply now ▼</Link>
                )}
               
                <Link to="/careers" className="talent-community">Cancel</Link>
            </div>
        </div>
    </div>
      </>
    )}

    <Footer />
  </div>
);
};

export default JobDescription;
