import React from 'react';
import '../Styles/Register.css'
import data from '../markData.json';
import Footer from './Footer';
import Header from './Header';
import "@fontsource/roboto"; // Defaults to weight 400
import "@fontsource/roboto/400.css"; // Specify weight
import "@fontsource/roboto/400-italic.css"; // Specify weight and style

const Register = () => {

  const { register} = data;
  // const { div } = register;
  return (
    <div className="registerpage">
      <div className="div">
        <div className="overlap">
          <img className="line" src={data.loginpage.div.img.line} alt="Line Decoration" />
          <div className="frame">
            <img className="brandmark-design" src={data.loginpage.div.img.brandmark} alt="Brandmark" />
          </div>
        </div>
        <Header/>
        <div className="overlap-2">
          <div className="overlap-wrapper">
            <div className="overlap-3">
              <div className="overlap-group-wrapper">
                <div className="rectangle"></div>
                <div className="group-3">
                  <div className="text-wrapper-6">{data.loginpage.div.registerpage['text-wrapper-6']}</div>
                  <div className="div-wrapper">
                    <div className="text-wrapper-7">{data.loginpage.div.registerpage['text-wrapper-7']}</div>
                  </div>
                  <div className="group-4-register">
                    <div className="overlap-4">
                      <input type='text' className="text-wrapper-8" placeholder={data.loginpage.div.registerpage['text-wrapper-8']}/>
                    </div>
                    <div className="overlap-group-3-12">
                      <input type='text' className="text-wrapper-9" placeholder={data.loginpage.div.registerpage['text-wrapper-9']}/>
                      <img className="clarity-eye-hide" src={data.loginpage.div.img.eye} alt="Hide Eye Icon" />
                    </div>
                  </div>
                </div>
                <div className="brandmark-design-wrapper">
                  <img className="brandmark-design-2" src={data.loginpage.div.img.brandmark} alt="Brandmark Design 2" />
                </div>
              </div>
              <img className="group-5" src={data.loginpage.div.img['design-2']} alt="GroupDesign5" />
              <img className="group-6" src={data.loginpage.div.img['design-1']} alt="GroupDesign6" />
            </div>
          </div>
          <img className="work-from-home" src={data.loginpage.div.img['men-1']} alt="Work From Home" />
          <div className="group-7">
            <div className="group-8">
              <div className="overlap-group-4">
                <img className="u-angle-down" src={data.loginpage.div.img.dropdown} alt="Angle Down Icon" />
                <div className="text-wrapper-10">{data.loginpage.div.registerpage['text-wrapper-10']}</div>
              </div>
            </div>
            <div className="group-9">
              <div className="overlap-5">
                <img className="u-angle-down-2" src={data.loginpage.div.img.dropdown} alt="Angle Down Icon" />
                <div className="text-wrapper-11">{data.loginpage.div.registerpage['text-wrapper-11']}</div>
              </div>
            </div>
            <div className="group-10">
              <div className="overlap-5">
                <img className="u-angle-down-2" src={data.loginpage.div.img.dropdown} alt="AngelDownIcon" />
                <div className="text-wrapper-12">{data.loginpage.div.registerpage['text-wrapper-12']}</div>
              </div>
            </div>
            <p className="p">{data.loginpage.div.registerpage['p']}</p>
            <div className="text-wrapper-13">{data.loginpage.div.registerpage['text-wrapper-13']}</div>
          </div>
          <div className="text-wrapper-14">{data.loginpage.div.registerpage['text-wrapper-14']}</div>
        </div>
        <div className="group-11">
          <div className="overlap-6">
            <Footer/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;