import React, { useState } from 'react';
import '../Styles/About.css'; // Assuming you have the CSS file
import  data from '../markData.json';
import Footer from './Footer';
import { ArrowRight } from 'react-bootstrap-icons';
import "@fontsource/roboto"; // Defaults to weight 400
import "@fontsource/roboto/400.css"; // Specify weight
import "@fontsource/roboto/400-italic.css"; // Specify weight and style
import Header from './Header';
import Error from '../Error';

const About = () => {

    try {
        
        const [isMenuOpen, setIsMenuOpen] = useState(false);
   

     
  
        return (
          
         
          <div>
            <Header/>
          {/* Header Section */}
          <section className="section">
              <div className="container-large-about">
                  <div className="header-layout-about">
                      <div className="header-content-about">
                          <h1 className="heading extra-large">
                              <span className="text-gradient-overlay-about">{data.about.header.heading}</span>
                          </h1>
                          <p className="paragraph large">{data.about.header.paragraph}</p>
                      </div>
                  </div>
              </div>
          </section>
      
          {/* Mission Section */}
          <section className="section">
              <div className="container-large-about">
                          <div className="image-wrapper background-color-accent">
                          <div className="feature-content-about">
                              <div className="text-color-accent text-weight-medium-about">{data.about.mission.heading}</div>
                              <p className="innovate-para">{data.about.mission['sub-heading']}</p>
                          </div>
                              <img src={data.about.mission['mission-img']} alt="About image" className="feature-image-about luminosity" />
                          </div>
              </div>
          </section>
      
          {/* Services Section */}
          <section className="section">
              <div className="container-large-about">
                  <h2 className="heading large-1 "><span className="text-gradient-overlay-about">{data.about.service.heading}</span></h2>
                  <div className="card-container">
                  <div className="card-item-one">
                <img src={data.contactuspage.div.img['servicenow-img']} alt="img" className='card-image-about' />
                <h3 className="heading-about medium">{data.contactuspage.div.frame['text-wrapper-23']}</h3>
                <p className='card-text'>{data.contactuspage.div.frame['text-wrapper-24']}</p>
                {/* service links comment for now */}
                {/* <a href="/servicenow" className="explr-btn-arrow"><ArrowRight/></a> */}
                <div class="custom-shape-divider-bottom-1739374922">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
          </svg>
      </div>
      <div class="custom-shape-divider-bottom-1739375067">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
          </svg>
      </div>
              </div>
              <div className="card-item-two">
              <img src={data.contactuspage.div.img['web-img']} alt="img" className='card-image-about' />
                <h3 className="heading-about medium">{data.contactuspage.div.frame['text-wrapper-25']}</h3>
                <p className='card-text'>{data.contactuspage.div.frame['text-wrapper-26']}</p>
                 {/* service links comment for now */}
                {/* <a href="/webdevelopment" className="explr-btn-arrow"><ArrowRight/></a> */}
                <div class="custom-shape-divider-bottom-1739374922">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
          </svg>
      </div>
      <div class="custom-shape-divider-bottom-1739375067">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
          </svg>
      </div>
              </div>
              <div className="card-item-three">
              <img src={data.contactuspage.div.img['BI-img']} alt="img" className='card-image-about' />
                <h3 className="heading-about medium">{data.contactuspage.div.frame['text-wrapper-27']}</h3>
                <p className='card-text'>{data.contactuspage.div.frame['text-wrapper-28']}</p>
                {/* service links comment for now */}
                {/* <a href="/businessintelligence" className="explr-btn-arrow"><ArrowRight/></a> */}
                <div class="custom-shape-divider-bottom-1739374922">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
          </svg>
      </div>
      <div class="custom-shape-divider-bottom-1739375067">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
          </svg>
      </div>
              </div>
              <div className="card-item-four">
              <img src={data.contactuspage.div.img['Ai-img']} alt="img" className='card-image-about' />
                <h3 className="heading-about medium">{data.contactuspage.div.frame['text-wrapper-29']}</h3>
                <p className='card-text'>{data.contactuspage.div.frame['text-wrapper-30']}</p>
                {/* service links comment for now */}
                {/* <a href="/artificialintelligence" className="explr-btn-arrow"><ArrowRight/></a> */}
                <div class="custom-shape-divider-bottom-1739374922">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
          </svg>
      </div>
      <div class="custom-shape-divider-bottom-1739375067">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
          </svg>
      </div>
      
              </div>
              <div className="card-item-five">
              <img src={data.contactuspage.div.img['cyber-img']} alt="img" className='card-image-about' />
                <h3 className="heading-about medium">{data.contactuspage.div.frame['text-wrapper-31']}</h3>
                <p className='card-text'>{data.contactuspage.div.frame['text-wrapper-32']}</p>
                {/* service links comment for now */}
                {/* <a href="#" className="explr-btn-arrow"><ArrowRight/></a> */}
                <div class="custom-shape-divider-bottom-1739374922">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
          </svg>
      </div>
      <div class="custom-shape-divider-bottom-1739375067">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
          </svg>
      </div>
              </div>
              <div className="card-item-six">
              <img src={data.contactuspage.div.img['3d-img']} alt="img" className='card-image-about' />
                <h3 className="heading-about medium">{data.contactuspage.div.frame['text-wrapper-34']}</h3>
                <p className='card-text'>{data.contactuspage.div.frame['text-wrapper-35']}</p>
                {/* service links comment for now */}
                {/* <a href="#" className="explr-btn-arrow"><ArrowRight/></a> */}
                <div class="custom-shape-divider-bottom-1739374922">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
          </svg>
      </div>
      <div class="custom-shape-divider-bottom-1739375067">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
          </svg>
      </div>
      
          </div>
          </div>
          </div>
          </section>
          {/* FAQ Section */}
          {/* <!-- FAQ section --> */}
          <section class="section">
              <div class="container-small-about">
                  <div class="faq-wrapper-about">
                      <div class="faq-content">
                          <h2><span class="text-gradient-overlay-about">{data.about['mark-qus'].heading}</span></h2>
                          <p class="paragraph large">{data.about['mark-qus']['sub-heading']}</p>
                      </div>
                      <div class="faq-list">
                          <div class="faq-accordion-about">
                              <div class="faq-question">
                                  <h3 class="faq-question-title">{data.about['mark-qus']['qus-1']}</h3>
                                  <p class="faq-answer-about">{data.about['mark-qus']['ans-1']}</p>
                              </div>
                              <div class="faq-question">
                                  <h3 class="faq-question-title">{data.about['mark-qus']['qus-2']}</h3>
                                  <div class="faq-answer-about">
                                  <p className='lists'><span>&#10003;</span>&nbsp;{data.about['mark-qus']['ans-2']}</p>
                                  <p className='lists'><span>&#10003;</span>&nbsp;{data.about['mark-qus']['ans2-1']}</p>
                                  <p className='lists'><span>&#10003;</span>&nbsp;{data.about['mark-qus']['ans2-2']}</p>
                                  <p className='lists'><span>&#10003;</span>&nbsp;{data.about['mark-qus']['ans2-3']}</p>
                                  <p className='lists'><span>&#10003;</span>&nbsp;{data.about['mark-qus']['ans2-4']}</p>
                                  <p className='lists'><span>&#10003;</span>&nbsp;{data.about['mark-qus']['ans2-5']}</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div> 
          </section>
      
          {/* Call to Action (CTA) Section */}
          <section className="section cta">
              <div className="container-small-about">
                  <h2 className="heading large-3"><span className="text-gradient-overlay-about">{data.about['join-team'].heading}</span></h2>
                  <p className="paragraph">{data.about['join-team']['sub-head']}</p>
                  <a href="/careers" className="button small w-button" aria-label="See open positions">{data.about['join-team']['postion-btn']}</a>
              </div>
          </section>
      
          {/* Footer Section */}
          <Footer/>
          </div>
      
      
        );

    } catch (error) {
        if(error) {
        console.log("this is error:",error);
        return <Error />
    }
    }
    
};

export default About;
