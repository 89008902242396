import '../Styles/BusinessIntelligence.css';
import React, { useState, useEffect } from "react";
import Footer from './Footer';
import "@fontsource/roboto"; // Defaults to weight 400
import "@fontsource/roboto/400.css"; // Specify weight
import "@fontsource/roboto/400-italic.css"; // Specify weight and style
import data from "../markData.json";
import brandimg from '././brandmark-design.png';
import LoginModal from './Login.js'; 

const BusinessIntelligence = () => {
    const { BusinessIntelligence } = data;
const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
    return (
      <div>
          {/* Navigation Bar */}
      <div data-animation="default" className="nav w-nav" data-easing2="ease" data-collapse="medium" role="banner">
        <div className="nav-container">
          <div className="logo-wrapper">
            <a href="/" className="logo w-nav-brand">
              <img className="logo-img" src={brandimg} alt="Logo" />
            </a>
            <nav className={`nav-menu w-nav-menu ${isMenuOpen ? 'open' : ''}`}>
              <a href="/" className="nav-link w-nav-link">{data.header.div['text-wrapper-1-h ']}</a>
              <a href="/about" className="nav-link w-nav-link" style={{ backgroundImage: "url('/images/home/cardview-back-img.png')", backgroundSize: 'contain' }}>{data.header.div['text-wrapper-2-h ']}</a>
              <a href="/careers" className="nav-link w-nav-link" >{data.header.div['text-wrapper-3-h']}</a>
              <a href="/contact" className="nav-link w-nav-link">{data.header.div['text-wrapper-4-h ']}</a>
            </nav>
          </div>
          <div className="actions-wrapper">
            <button onClick={() => setShowLoginModal(true)} className="button small hide-mobile-portrait w-button">Login</button>
             {/* Mobile Menu Button */}
             <div className="nav-menu-button w-nav-button" onClick={toggleMenu}>
              <div className="menu-icon">
                <div className="menu-icon-line-top"></div>
                <div className="menu-icon-line-bottom"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Dropdown Menu */}
      {isMenuOpen && (
          <div className="dropdown-menu">
            <a href="/about" className="dropdown-link">About</a>
            <a href="/careers" className="dropdown-link">Careers</a>
            <a href="/contact" className="dropdown-link">Contact</a>
            <a onClick={() => setShowLoginModal(true)} className="dropdown-link">Login</a>
          </div>
        )}

      {/* Login Modal (popup) */}
      {showLoginModal && <LoginModal closeModal={() => setShowLoginModal(false)} />}

      {/* Job Details */}
      <div className="sub-heading">
      <nav class="breadcrumb">
    <a href="/about">About</a>
    <span>&gt;</span>
    <a href="#" style={{ color: 'blue' }}>Business Intelligence</a>
    
  </nav>
      </div>

<div class="business-intelligence">
           
  <div class="frame-1000006978">
    <div class="frame-1000006972">
      <div class="frame-1000006970">
        <div class="frame-1000006957">
          <div class="frame-1000006956">
            <div class="business-intelligence2">Business Intelligence</div>
          </div>
          <div class="frame-1000006955">
            <div
              class="bi-para-1"
            >
              Business Intelligence involves collecting, managing, and analyzing
              data to generate insights that inform business operations and
              strategies. Business Intelligence can help businesses predict what
              might happen in the future and track key metrics. Business
              Intelligence is also known as a decision support system.
            </div>
          </div>
        </div>
        <div class="frame-1000006969">
          <div class="frame-1000006714">
            <div class="frame-1000006965">
              <div class="frame-1000006958">
                <div class="business-intelligence3">BUSINESS INTELLIGENCE</div>
              </div>
              <div class="frame-1000006959">
                <div class="descriptive-analytics">descriptive analytics</div>
              </div>
            </div>
            <div class="frame-1000006968">
              <div class="frame-1000006715">
                <div class="frame-1000006967">
                  <div class="frame-1000006964">
                    <div class="collects-analyzes-and-visualizes-data">
                      collects, analyzes and visualizes data
                    </div>
                  </div>
                  <div class="frame-1000006963">
                    <div class="identifies-pain-points">
                      identifies pain points
                    </div>
                  </div>
                  <div class="frame-1000006962">
                    <div class="generates-reports">generates reports</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="frame-1000006966">
              <div class="frame-1000006960">
                <div class="business-analytics">BUSINESS ANALYTICS</div>
              </div>
              <div class="frame-1000006961">
                <div
                  class="diagnostic-analytics-predictive-analytics-prescriptive-analytics"
                >
                  diagnostic analytics
                  <br />
                  predictive analytics
                  <br />
                  prescriptive analytics
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="frame-1000006971">
        <div
          class="on-premises-these-run-on-your-organization-s-infrastructure-and-are-commonly-used-with-traditional-data-warehouses-that-are-also-running-on-premises-they-can-however-be-less-scalable-than-cloud-solutions-open-source-these-tools-are-cost-effective-and-if-they-re-cloud-based-can-also-save-you-money-on-infrastructure-costs-however-open-source-tools-still-require-a-level-of-knowledge-and-hand-coding-to-be-able-to-use-them-effectively-cloud-based-cloud-based-business-intelligence-tools-are-especially-good-at-handling-streaming-data-and-large-volumes-of-data-they-can-also-be-cost-effective-because-the-infrastructure-and-expertise-required-to-maintain-the-environment-are-handled-by-the-cloud-vendor"
        >
          <span>
            <span
              class="on-premises-these-run-on-your-organization-s-infrastructure-and-are-commonly-used-with-traditional-data-warehouses-that-are-also-running-on-premises-they-can-however-be-less-scalable-than-cloud-solutions-open-source-these-tools-are-cost-effective-and-if-they-re-cloud-based-can-also-save-you-money-on-infrastructure-costs-however-open-source-tools-still-require-a-level-of-knowledge-and-hand-coding-to-be-able-to-use-them-effectively-cloud-based-cloud-based-business-intelligence-tools-are-especially-good-at-handling-streaming-data-and-large-volumes-of-data-they-can-also-be-cost-effective-because-the-infrastructure-and-expertise-required-to-maintain-the-environment-are-handled-by-the-cloud-vendor-span"
            >
              On-premises
            </span>
            <span
              class="on-premises-these-run-on-your-organization-s-infrastructure-and-are-commonly-used-with-traditional-data-warehouses-that-are-also-running-on-premises-they-can-however-be-less-scalable-than-cloud-solutions-open-source-these-tools-are-cost-effective-and-if-they-re-cloud-based-can-also-save-you-money-on-infrastructure-costs-however-open-source-tools-still-require-a-level-of-knowledge-and-hand-coding-to-be-able-to-use-them-effectively-cloud-based-cloud-based-business-intelligence-tools-are-especially-good-at-handling-streaming-data-and-large-volumes-of-data-they-can-also-be-cost-effective-because-the-infrastructure-and-expertise-required-to-maintain-the-environment-are-handled-by-the-cloud-vendor-span2"
            >
              :
            </span>
            <span
              class="on-premises-these-run-on-your-organization-s-infrastructure-and-are-commonly-used-with-traditional-data-warehouses-that-are-also-running-on-premises-they-can-however-be-less-scalable-than-cloud-solutions-open-source-these-tools-are-cost-effective-and-if-they-re-cloud-based-can-also-save-you-money-on-infrastructure-costs-however-open-source-tools-still-require-a-level-of-knowledge-and-hand-coding-to-be-able-to-use-them-effectively-cloud-based-cloud-based-business-intelligence-tools-are-especially-good-at-handling-streaming-data-and-large-volumes-of-data-they-can-also-be-cost-effective-because-the-infrastructure-and-expertise-required-to-maintain-the-environment-are-handled-by-the-cloud-vendor-span3"
            >
              These run on your organization’s infrastructure and are commonly
              used with traditional data warehouses that are also running
              on-premises. They can, however, be less scalable than cloud
              solutions.
              <br />
            </span>
            <span
              class="on-premises-these-run-on-your-organization-s-infrastructure-and-are-commonly-used-with-traditional-data-warehouses-that-are-also-running-on-premises-they-can-however-be-less-scalable-than-cloud-solutions-open-source-these-tools-are-cost-effective-and-if-they-re-cloud-based-can-also-save-you-money-on-infrastructure-costs-however-open-source-tools-still-require-a-level-of-knowledge-and-hand-coding-to-be-able-to-use-them-effectively-cloud-based-cloud-based-business-intelligence-tools-are-especially-good-at-handling-streaming-data-and-large-volumes-of-data-they-can-also-be-cost-effective-because-the-infrastructure-and-expertise-required-to-maintain-the-environment-are-handled-by-the-cloud-vendor-span4"
            >
              <br />
            </span>
            <span
              class="on-premises-these-run-on-your-organization-s-infrastructure-and-are-commonly-used-with-traditional-data-warehouses-that-are-also-running-on-premises-they-can-however-be-less-scalable-than-cloud-solutions-open-source-these-tools-are-cost-effective-and-if-they-re-cloud-based-can-also-save-you-money-on-infrastructure-costs-however-open-source-tools-still-require-a-level-of-knowledge-and-hand-coding-to-be-able-to-use-them-effectively-cloud-based-cloud-based-business-intelligence-tools-are-especially-good-at-handling-streaming-data-and-large-volumes-of-data-they-can-also-be-cost-effective-because-the-infrastructure-and-expertise-required-to-maintain-the-environment-are-handled-by-the-cloud-vendor-span5"
            >
              Open source
            </span>
            <span
              class="on-premises-these-run-on-your-organization-s-infrastructure-and-are-commonly-used-with-traditional-data-warehouses-that-are-also-running-on-premises-they-can-however-be-less-scalable-than-cloud-solutions-open-source-these-tools-are-cost-effective-and-if-they-re-cloud-based-can-also-save-you-money-on-infrastructure-costs-however-open-source-tools-still-require-a-level-of-knowledge-and-hand-coding-to-be-able-to-use-them-effectively-cloud-based-cloud-based-business-intelligence-tools-are-especially-good-at-handling-streaming-data-and-large-volumes-of-data-they-can-also-be-cost-effective-because-the-infrastructure-and-expertise-required-to-maintain-the-environment-are-handled-by-the-cloud-vendor-span6"
            >
              : 
            </span>
            <span
              class="on-premises-these-run-on-your-organization-s-infrastructure-and-are-commonly-used-with-traditional-data-warehouses-that-are-also-running-on-premises-they-can-however-be-less-scalable-than-cloud-solutions-open-source-these-tools-are-cost-effective-and-if-they-re-cloud-based-can-also-save-you-money-on-infrastructure-costs-however-open-source-tools-still-require-a-level-of-knowledge-and-hand-coding-to-be-able-to-use-them-effectively-cloud-based-cloud-based-business-intelligence-tools-are-especially-good-at-handling-streaming-data-and-large-volumes-of-data-they-can-also-be-cost-effective-because-the-infrastructure-and-expertise-required-to-maintain-the-environment-are-handled-by-the-cloud-vendor-span7"
            >
              These tools are cost effective and, if they’re cloud based, can
              also save you money on infrastructure costs. However, open source
              tools still require a level of knowledge and hand coding to be
              able to use them effectively.
            </span>
            <span
              class="on-premises-these-run-on-your-organization-s-infrastructure-and-are-commonly-used-with-traditional-data-warehouses-that-are-also-running-on-premises-they-can-however-be-less-scalable-than-cloud-solutions-open-source-these-tools-are-cost-effective-and-if-they-re-cloud-based-can-also-save-you-money-on-infrastructure-costs-however-open-source-tools-still-require-a-level-of-knowledge-and-hand-coding-to-be-able-to-use-them-effectively-cloud-based-cloud-based-business-intelligence-tools-are-especially-good-at-handling-streaming-data-and-large-volumes-of-data-they-can-also-be-cost-effective-because-the-infrastructure-and-expertise-required-to-maintain-the-environment-are-handled-by-the-cloud-vendor-span8"
            >
              <br />
              <br />
              Cloud-based
            </span>
            <span
              class="on-premises-these-run-on-your-organization-s-infrastructure-and-are-commonly-used-with-traditional-data-warehouses-that-are-also-running-on-premises-they-can-however-be-less-scalable-than-cloud-solutions-open-source-these-tools-are-cost-effective-and-if-they-re-cloud-based-can-also-save-you-money-on-infrastructure-costs-however-open-source-tools-still-require-a-level-of-knowledge-and-hand-coding-to-be-able-to-use-them-effectively-cloud-based-cloud-based-business-intelligence-tools-are-especially-good-at-handling-streaming-data-and-large-volumes-of-data-they-can-also-be-cost-effective-because-the-infrastructure-and-expertise-required-to-maintain-the-environment-are-handled-by-the-cloud-vendor-span9"
            >
              :
            </span>
            <span
              class="on-premises-these-run-on-your-organization-s-infrastructure-and-are-commonly-used-with-traditional-data-warehouses-that-are-also-running-on-premises-they-can-however-be-less-scalable-than-cloud-solutions-open-source-these-tools-are-cost-effective-and-if-they-re-cloud-based-can-also-save-you-money-on-infrastructure-costs-however-open-source-tools-still-require-a-level-of-knowledge-and-hand-coding-to-be-able-to-use-them-effectively-cloud-based-cloud-based-business-intelligence-tools-are-especially-good-at-handling-streaming-data-and-large-volumes-of-data-they-can-also-be-cost-effective-because-the-infrastructure-and-expertise-required-to-maintain-the-environment-are-handled-by-the-cloud-vendor-span10"
            >
              Cloud-based business intelligence tools are especially good at
              handling streaming data and large volumes of data. They can also
              be cost effective, because the infrastructure and expertise
              required to maintain the environment are handled by the cloud
              vendor.
            </span>
          </span>
        </div>
      </div>
    </div>
    <div class="frame-1000006977">
      <div class="frame-1000006976">
        <div class="importance-of-bi">Importance of BI</div>
      </div>
      <div class="frame-1000006975">
        <div class="frame-10000067152">
          <div class="frame-10000069672">
            <div class="frame-1000006964">
              <div
                class="data-quality-having-quality-data-is-critical-to-achieving-good-business-analytics-bad-data-results-in-bad-business-intelligence-data-quality-is-a-challenge-for-two-primary-reasons-one-data-is-outdated-which-occurs-easily-in-a-large-complex-organization-two-companies-aren-t-taking-the-time-to-practice-good-data-hygiene"
              >
                <span>
                  <span
                    class="data-quality-having-quality-data-is-critical-to-achieving-good-business-analytics-bad-data-results-in-bad-business-intelligence-data-quality-is-a-challenge-for-two-primary-reasons-one-data-is-outdated-which-occurs-easily-in-a-large-complex-organization-two-companies-aren-t-taking-the-time-to-practice-good-data-hygiene-span"
                  >
                    Data quality:
                  </span>
                  <span
                    class="data-quality-having-quality-data-is-critical-to-achieving-good-business-analytics-bad-data-results-in-bad-business-intelligence-data-quality-is-a-challenge-for-two-primary-reasons-one-data-is-outdated-which-occurs-easily-in-a-large-complex-organization-two-companies-aren-t-taking-the-time-to-practice-good-data-hygiene-span2"
                  >
                    <br />
                    Having quality data is critical to achieving good business
                    analytics. Bad data results in bad business intelligence.
                    Data quality is a challenge for two primary reasons. One,
                    data is outdated, which occurs easily in a large, complex
                    organization. Two, companies aren’t taking the time to
                    practice good data hygiene.
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="frame-1000006973">
          <div class="frame-1000006967">
            <div class="frame-10000069642">
              <div
                class="critical-data-buried-in-different-systems-when-data-is-in-different-systems-and-inaccessible-to-other-systems-because-the-software-may-not-be-compatible-or-the-business-unit-tightly-controls-user-permissions-it-s-called-siloed-data"
              >
                <span>
                  <span
                    class="critical-data-buried-in-different-systems-when-data-is-in-different-systems-and-inaccessible-to-other-systems-because-the-software-may-not-be-compatible-or-the-business-unit-tightly-controls-user-permissions-it-s-called-siloed-data-span"
                  >
                    Critical data buried in different systems:
                  </span>
                  <span
                    class="critical-data-buried-in-different-systems-when-data-is-in-different-systems-and-inaccessible-to-other-systems-because-the-software-may-not-be-compatible-or-the-business-unit-tightly-controls-user-permissions-it-s-called-siloed-data-span2"
                  >
                    <br />
                    When data is in different systems and inaccessible to other
                    systems, because the software may not be compatible or the
                    business unit tightly controls user permissions, it’s called
                    siloed data.
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="frame-1000006974">
          <div class="frame-1000006967">
            <div class="frame-1000006964">
              <div
                class="lack-of-expertise-using-business-intelligence-tools-requires-significant-expertise-this-means-that-only-a-few-key-people-in-your-organization-have-the-skills-to-use-the-business-intelligence-tools-effectively-which-in-turn-can-create-a-bottleneck"
              >
                <span>
                  <span
                    class="lack-of-expertise-using-business-intelligence-tools-requires-significant-expertise-this-means-that-only-a-few-key-people-in-your-organization-have-the-skills-to-use-the-business-intelligence-tools-effectively-which-in-turn-can-create-a-bottleneck-span"
                  >
                    Lack of expertise:
                  </span>
                  <span
                    class="lack-of-expertise-using-business-intelligence-tools-requires-significant-expertise-this-means-that-only-a-few-key-people-in-your-organization-have-the-skills-to-use-the-business-intelligence-tools-effectively-which-in-turn-can-create-a-bottleneck-span2"
                  >
                    <br />
                    Using business intelligence tools requires significant
                    expertise. This means that only a few key people in your
                    organization have the skills to use the business
                    intelligence tools effectively, which in turn can create a
                    bottleneck.
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 
</div>
 <Footer/>
 </div>

      
    );
};

export default BusinessIntelligence;