import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../Styles/header.css';
import data from '../markData.json';
import Popup from 'reactjs-popup';
import "@fontsource/roboto"; // Defaults to weight 400
import "@fontsource/roboto/400.css"; // Specify weight
import "@fontsource/roboto/400-italic.css"; // Specify weight and style
// import 'reactjs-popup/dist/index.css';
import LoginModal from './Login.js'; // Assuming LoginModal is a separate component
   

const Header = () => {

   const [showLoginModal, setShowLoginModal] = useState(false); // State to control the login modal
  //  header page active function
      const location = useLocation(); // Get the current route
        // Function to check if a link is active
        const isActive = (path) => location.pathname === path ? "active" : "";
  
        // header dropdown
          const [menuOpen, setMenuOpen] = useState(false);
          const toggleMenu = () => {
            setMenuOpen(!menuOpen);
        };
  
  return (
    <div>
  {/* Navigation Bar  */}
  <div className="navbar">
  <div className="nav-container">
      <div className="logo">
          <a href="/"><img  className="header-logo-img"  src={data.careers.div.img.brandmark} alt="Logo" /></a>
      </div>
      <ul className={`nav-links ${menuOpen ? 'show' : ''}`} onClick={() => setMenuOpen(false)}>
          <li><a href="/" className={isActive("/")}>Home</a></li>
          <li><a href="/about" className={isActive("/about")}>About</a></li>
          <li><a href="/careers" className={isActive("/careers")}>Careers</a></li>
          <li><a href="/contact" className={isActive("/contact")}>Contact</a></li>
      </ul>
  </div>
  {/* <button className="header-login" onClick={() => setShowLoginModal(true)}>Login</button> */}
  <button className="hamburger" onClick={() => setMenuOpen(!menuOpen)}>{menuOpen ? "✖" : "☰"}</button>
</div>

  {/* Login Modal (popup) */}
      {showLoginModal && <LoginModal closeModal={() => setShowLoginModal(false)} />}
</div>
  
  );
};

export default Header;

