import React from "react";
import { useState } from "react";
import data from "../markData.json"; // Adjust the path as necessary
import "../Styles/servicenow.css";
import Footer from './Footer';
import LoginModal from './Login.js';
import "@fontsource/roboto"; // Defaults to weight 400
import "@fontsource/roboto/400.css"; // Specify weight
import "@fontsource/roboto/400-italic.css"; // Specify weight and style

export const AboutUsServiceNow = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
        const [showLoginModal, setShowLoginModal] = useState(false);
            const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  return (
    <div className="about-us-service-now">
      <div className="div">
         {/* header component comes from header.js */}
        <div data-animation="default" className="nav w-nav" data-easing2="ease" data-collapse="medium" role="banner">
        <div className="nav-container">
          {/* Logo */}
          <div className="logo-wrapper">
            <a href="/" className="logo w-nav-brand">
              <img className="logo-img" src={data.header.div.brandmark} alt="Logo" />
            </a>

            {/* Navigation Links */}
            <nav className={`nav-menu w-nav-menu ${isMenuOpen ? 'open' : ''}`}>
              <a href="/" className="nav-link w-nav-link">
                {data.header.div['text-wrapper-1-h ']}
              </a>
              <a href="/about" className="nav-link w-nav-link"  style={{ backgroundImage: "url('/images/home/cardview-back-img.png')", backgroundSize: 'contain' }}>{data.header.div['text-wrapper-2-h ']}</a>
              <a href="/careers" className="nav-link w-nav-link">{data.header.div['text-wrapper-3-h']}</a>
              <a href="/contact" className="nav-link w-nav-link">{data.header.div['text-wrapper-4-h ']}</a>
            </nav>
          </div>

          {/* Actions */}
          <div className="actions-wrapper">
          <button onClick={() => setShowLoginModal(true)} className="button small hide-mobile-portrait w-button">Login</button>

            {/* Mobile Menu Button */}
            <div className="nav-menu-button w-nav-button" onClick={toggleMenu}>
              <div className="menu-icon">
                <div className="menu-icon-line-top"></div>
                <div className="menu-icon-line-bottom"></div>
              </div>
            </div>
          </div>
        </div>

        {/* Dropdown Menu */}
        {isMenuOpen && (
          <div className="dropdown-menu">
            <a href="/" className="dropdown-link">Home</a>
            <a href="/careers" className="dropdown-link">Careers</a>
            <a href="/contact" className="dropdown-link">Contact</a>
            <a onClick={() => setShowLoginModal(true)} className="dropdown-link">Login</a>
          </div>
        )}
      </div>
      {showLoginModal && <LoginModal closeModal={() => setShowLoginModal(false)} />}
        {/* <div className="overlap-wrapper-1">
          <div className="overlap-1"> */}
            <div className="text-wrapper-1">{data.aboutData.service}</div>
            {/* <img className="icon-HRSD" alt="Icon customer" src={aboutUs.services[0].iconhr} />
          </div>
        </div>
        <div className="overlap-wrapper">
          <div className="overlap-2">
            <div className="text-wrapper-2">{aboutUs.services[1].name}</div>
            <img className="icon-customer" alt="Icon customer" src={aboutUs.services[1].iconcs} />
          </div>
        </div>
        <div className="frame-2">
          <img className="icon-ITOM" alt="Icon ITOM" src={aboutUs.services[2].iconit} />
          <div className="text-wrapper-3">{aboutUs.services[2].name}</div>
        </div>
        <p className="p">{aboutUs.description}</p>
        <p className="text-wrapper-4">Explore the ServiceNow AI Platform</p>
        <p className="text-wrapper-5">{aboutUs.aiPlatform}</p>
        <div className="div-wrapper">
          <div className="overlap-3">
            <div className="text-wrapper-6">{aboutUs.services[3].name}</div>
            <img className="icon-ITSM-cloud" alt={`Icon ${aboutUs.services[3].name}`} src={aboutUs.services[3].iconsm} />
          </div>
        </div>
        <div className="overlap-4">
          <img className="line" alt="Line" src={aboutUs.lineImage} />
          <div className="brandmark-design-wrapper">
            <img className="brandmark-design" alt="Brandmark design" src={aboutUs.brandmark} />
          </div>
        </div>
        <div className="frame-4">
          <p className="text-wrapper-13">{aboutUs.helpText}</p>
          <p className="text-wrapper-12">{aboutUs.findAnswersText}</p> */}
        {/* </div> */}
        {/* footer component comes from footer.js */}
        <Footer />
        </div>
    </div>
  );
};

export default AboutUsServiceNow;