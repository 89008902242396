import React, { useState, useEffect } from "react";
import "../Styles/ApplyJob.css";
import data from "../markData.json";
import Footer from "./Footer";
import { useNavigate, Link, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import brandimg from '././brandmark-design.png';
import LoginModal from './Login.js'; // Assuming LoginModal is a separate component
import "@fontsource/roboto"; // Defaults to weight 400
import "@fontsource/roboto/400.css"; // Specify weight
import "@fontsource/roboto/400-italic.css"; // Specify weight and style
import { useQuery } from '@tanstack/react-query';
import Error from "../Error.js";

const ApplyJob = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const jobDetail = location.state?.jobDetail;
  const [showLoginModal, setShowLoginModal] = useState(false); // State to control the login modal
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [responseMessage, setResponseMessage] = useState("");

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    address: "",
    city: "",
    pincode: "",
    gender: "",
    job_role: jobDetail?.job_title || "",
    job_id: id || "",
    submitted_on: new Date().toISOString(),
  });

  const [files, setFiles] = useState([]);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  useEffect(() => {
    if (responseMessage) {
      const timer = setTimeout(() => setResponseMessage(""), 3000);
      return () => clearTimeout(timer);
    }
  }, [responseMessage]);

  useEffect(() => {
    const fetchJobData = async () => {
      try {
        const response = await axios.get("/getCareers");
        if (response.status === 200) {
          const job = response.data.find((job) => job.job_id === id);
          if (job) {
            setFormData((prevData) => ({
              ...prevData,
              job_id: job.job_id,
              job_role: job.job_title,
            }));
          }
        }
      } catch (err) {
        console.error("Failed to load job data in apply job");
      } finally {
        setLoading(false);
      }
    };

    fetchJobData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (e) => {
    setFiles([...files, ...Array.from(e.target.files)]);
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const resetForm = () => {
    setFormData({
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      address: "",
      city: "",
      pincode: "",
      gender: "",
      job_role: "",
      job_id: "",
      submitted_on: new Date().toISOString(),
    });
    setFiles([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const form = new FormData();
    files.forEach((file) => form.append("resume", file));
    Object.entries(formData).forEach(([key, value]) => {
      form.append(key, value);
    });

    try {
      const response = await axios.post("/apply_job", form, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.status === 200) {
        setResponseMessage("Application submitted successfully!");
        resetForm();
        setTimeout(() => navigate("/careers"), 2000);
      } else {
        throw new Error("Failed to submit application");
      }
    } catch (err) {
      setResponseMessage("Error submitting application");
    } finally {
      setLoading(false);
    }
  };

  if (error) return <Error message={error} />;
 

   if (loading) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <div>
      {/* Navigation Bar */}
      <div className="navbar">
        <div className="nav-container">
          <div className="logo">
            <a href="/"><img src={brandimg} alt="Logo" height="80px" /></a>
          </div>
          <ul className={`nav-links ${isMenuOpen ? 'show' : ''}`} onClick={() => setIsMenuOpen(false)}>
            <li><a href="/" >Home</a></li>
            <li><a href="/about" >About</a></li>
            <li><a href="/careers"  style={{ backgroundImage: "url('/images/home/cardview-back-img.png')", backgroundSize: 'contain' }} >Careers</a></li>
            <li><a href="/contact" >Contact</a></li>
          </ul>
        </div>
        {/* <button className="header-login" onClick={() => setShowLoginModal(true)}>Login</button> */}
        <button className="hamburger" onClick={() => setIsMenuOpen(!isMenuOpen)}>{isMenuOpen ? "✖" : "☰"}</button>
      </div>

      
      <div class="sub-heading">
    <nav class="breadcrumb">
    <a href="/careers">Career</a>
        <span>›</span>
        <a href={`/jobdes/${id}`}>JobDescription</a>
        <span>›</span>
            <a href={`/applyjob/${id}`} style={{ color: 'blue' }}>ApplyJob</a>
    </nav>
    
        <nav class="breadcrumb-mobile">
            <a href={`/jobdes/${id}`}>
               
                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <polyline points="15 18 9 12 15 6"></polyline>
                </svg>
                Back to JobDescription
            </a>
        </nav>
  </div>


      {/* Login Modal (popup) */}
      {showLoginModal && <LoginModal closeModal={() => setShowLoginModal(false)} />}

       {/* Loading Spinner */}
       {loading && (
          <div className="loader-container">
              <div className="loader"></div>
              </div>
    )}


    {/* Show job details when data is loaded */}
    {!loading && (
      <>
        <div className="container">
          {responseMessage && (
            <div className={`response-message ${responseMessage.includes("success") ? "success" : "error"}`}>
              {responseMessage}
            </div>
          )}

          <div className="job-application-form">
            <img className="apply_job_header_img" src={data.applyJob.images.apply_job_header} alt="Apply Job" />
            <div className="apply_job_title">
              <p>
                <span><strong></strong></span>
              </p>
            </div>

            <form onSubmit={handleSubmit}>
  {/* First Name */}
  <div className="form-group">
    <div className="form-label-inline">
      <label htmlFor="first_name">
      <span className={`required ${formData.first_name ? 'label-color-black': 'label-color-red'}`}></span>First Name
      </label>
    </div>
    <input
      id="first_name"
      name="first_name"
      value={formData.first_name}
      onChange={handleChange}
      placeholder="Enter First Name..."
      required
    />
  </div>

  {/* Last Name */}
  <div className="form-group">
    <div className="form-label-inline">
      <label htmlFor="last_name">
      <span className={`required ${formData.last_name ? 'label-color-black': 'label-color-red'}`}></span>Last Name 
      </label>
    </div>
    <input
      id="last_name"
      name="last_name"
      value={formData.last_name}
      onChange={handleChange}
      placeholder="Enter Last Name..."
      required
    />
  </div>

  {/* Email */}
  <div className="form-group">
    <div className="form-label-inline">
      <label htmlFor="email">
      <span className={`required ${formData.email ? 'label-color-black': 'label-color-red'}`}></span>Email
      </label>
    </div>
    <input
      type="email"
      id="email"
      name="email"
      value={formData.email}
      onChange={handleChange}
      placeholder="Enter Email..."
      required
    />
  </div>
{/* Gender */}
  <div className="form-group">
                <label htmlFor="gender"><span className={`required ${formData.gender ? 'label-color-black': 'label-color-red'}`}></span>Gender</label>
                <select id="gender" name="gender" value={formData.gender} onChange={handleChange}>
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
              </div>

  {/* Phone Number */}
  <div className="form-group">
    <div className="form-label-inline">
      <label htmlFor="phone_number">
      <span className={`required ${formData.phone_number ? 'label-color-black': 'label-color-red'}`}></span>Phone Number 
      </label>
    </div>
    <input
      type="tel"
      id="phone_number"
      name="phone_number"
      value={formData.phone_number}
      onChange={handleChange}
      placeholder="Enter Phone Number..."
      required
    />
  </div>
  <div className="form-group">
     <label htmlFor="address">Address</label>
       <textarea 
       id="address" 
       name="address"
      value={formData.address} 
      onChange={handleChange} 
      rows="4" 
      placeholder="Enter Address"
      required
      ></textarea>
     </div>

  <div className="form-group">
        <label htmlFor="city">City</label>
        <input
      type="tel"
      id="city"
      name="city"
      value={formData.city}
      onChange={handleChange}
      placeholder="Enter city..."
      required
    />
    </div>

  {/* Pincode */}
  <div className="form-group">
    <div className="form-label-inline">
      <label htmlFor="pincode">
      <span className={`required ${formData.pincode ? 'label-color-black': 'label-color-red'}`}></span>Pincode 
      </label>
    </div>
    <input
      type="number"
      id="pincode"
      name="pincode"
      value={formData.pincode}
      onChange={handleChange}
      placeholder="Enter Pincode..."
      required
    />
  </div>

  {/* Upload Resume */}
  <div className="form-group">
    <div className="form-label-inline">
      <label htmlFor="resume">
      <span className={`required ${files.length > 0 ? 'label-color-black': 'label-color-red'}`}></span>Upload Your Resume (PDF only) 
      </label>
    </div>
    <input
      type="file"
      id="upload"
      name="resume"
      onChange={handleFileChange}
      accept=".pdf"
      required
    />
    <ul className="file-list">
      {files.length > 0 ? (
        files.map((file, index) => (
          <li key={file.name} className="file-item">
            {file.name}{" "}
            <button type="button" className="remove-btn" onClick={() => handleRemoveFile(index)}>
              X
            </button>
          </li>
        ))
      ) : (
        <p>No files uploaded</p>
      )}
    </ul>
  </div>

  {/* Submit Button */}
  <div className="button-group">
    {jobDetail && (
      <button type="submit" className="submit-button" disabled={loading}>
        {loading ? "Submitting..." : "Submit ▼"}
      </button>
    )}
    <Link to="/careers" className="cancel-button">Cancel</Link>
  </div>
</form>

          </div>
        </div>
       </>
      )}
      <Footer />
    </div>
  );
};

export default ApplyJob;
