import "../Styles/Maintenance.css";
const Maintenance = () => {
  return (
    <div className="maintenance">
        <div className="overlay-maintenance"></div>
        <div className="container-maintenance">
            <div className="flow-maintenance">
                <div className="content-maintenance">
                    <h1 className="head-maintain">Maintenance in Progress</h1>
                    <p className="para-maintain">We're currently reworking on the website to enhance your experience. Thank you for your patience!</p>
                </div>
                <h2 className="headTwo-maintain">What's Happening?</h2>
                <p className="para-maintain">Our team is working hard to bring you updates, enhancements, and fixes to make things better.</p>
            </div>
        </div>  
    </div>
  )
}

export default Maintenance
